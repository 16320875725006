.dropzone-container {
  display: flex;
  flex-direction: column;
}

.file-limit-description {
  font-style: italic;
  font-size: 0.8em;
}

.file-list {
  color: var(--black);
  margin-top: 0.5em;
  font-size: 0.8em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.file-list ul {
  margin-top: 0.25em;
  list-style-type: none;
}
.file-list ul li {
  display: flex;
  align-items: center;
}
.rejected {
  color: red;
}
