* {
  box-sizing: border-box;
  margin: 0;
  --primary: #f2f2f2;
  --flexible-blue: #053550;
  --flexible-light-blue: #01aed3;
  --flexible-green: #a9db11;
}
body {
  font-family: Roboto, "Open Sans", sans-serif;
  overflow-x: hidden;
}
.App {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}
.alert,
.success {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  height: 50vh;
  justify-content: center;
  text-align: center;
}
.alert-message,
.success-message {
  border-radius: 6px;
  padding: 2rem 4rem;
}
.alert-message p,
.success-message p {
  margin: 1rem 0;
}
.alert-message {
  background: rgba(255, 200, 200);
  border: 2px solid red;
}
.success-message {
  background: rgba(200, 255, 200);
  border: 2px solid green;
}
header,
footer,
main {
  grid-column: 1 / -1;
  padding: 1rem;
}
header {
  grid-row: 1 / 1;
  text-transform: capitalize;
  background: var(--flexible-blue);
  color: var(--primary);
}
footer {
  text-transform: capitalize;
}
main {
  grid-row: 2 / 2;
  margin: 0 5rem;
}
footer {
  align-items: center;
  display: flex;
  grid-row: 3 / -1;
  justify-content: center;
}
